
import DataTable from '@/components/mc-admin-datatable/DataTable.vue'
import FilterDropdown from '@/views/mc-admin-session/FilterDropdown.vue'
import { defineComponent, ref, onMounted, computed, inject, onUnmounted, watch } from 'vue'
import { useStore } from 'vuex'
import { setCurrentPageTitle, setCurrentPageTitleButton } from '@/core/helpers/breadcrumb'
import { getMedia } from '@/core/mc-admin-helpers/assets'
import {
  getStatusBadge,
  formatListDate,
  formatListHourInterval
} from '@/core/mc-admin-helpers/activity-session'
import { extractParticipantCount } from '@/core/mc-admin-helpers/learning-path'
import { Actions, MutationsActions } from '@/store/enums/StoreEnums'
import { ActivitySessionFilter } from '@/store/modules/ActivitySessionModule'
import router from '@/router/clean'
import { NewLearningPath } from '@/store/modules/LearningPathModule'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { MenuComponent } from '@/assets/ts/components'
import { ElNotification } from 'element-plus/es'
import { MetricsPayload } from '@/store/modules/StatisticModule'

export default defineComponent({
  name: 'learning-paths',
  components: {
    DataTable,
    FilterDropdown
  },
  setup () {
    const tableHeader = ref([
      {
        name: 'NAME',
        key: 'name',
        sortable: true
      },
      {
        name: 'STATUS',
        key: 'status',
        sortable: true
      },
      {
        name: 'SCHEDULED FOR',
        key: 'startDate',
        sortable: true
      },
      {
        name: 'PARTICIPANTS',
        key: 'participants',
        sortable: true
      },
      {
        name: 'ACTIVITIES',
        key: 'activities',
        sortable: true
      },
      {
        name: 'Actions',
        key: 'actions'
      }
    ])
    const tableSearch = ref<string>('')
    const itemsPerPage = ref<number>(5)
    const selectedPage = ref<number>(1)

    const store = useStore()

    const tableData = ref(computed(() => store.getters.getLearningPaths))
    const tableLoading = computed(() => store.getters.getLearningPathsLoading)

    const sessionCount = computed(() => store.getters.getLearningPathsCount)
    const tablePagesCount = computed(() => Math.ceil(sessionCount.value / itemsPerPage.value))

    let timeoutId = 0 as any

    const onItemsSearch = () => {
      clearTimeout(timeoutId)
      const payload = { filter: { title: tableSearch.value, limit: itemsPerPage.value, offset: itemsPerPage.value * (selectedPage.value - 1) } } as any
      const countPayload = { filter: { title: tableSearch.value } } as any
      timeoutId = setTimeout(() => {
        store.dispatch(MutationsActions.API_GET_MUTATE_LEARNING_PATHS, payload).then(() => {
          MenuComponent.reinitialization()
        })
        store.dispatch(MutationsActions.API_GET_MUTATE_LEARNING_PATHS_COUNT, countPayload)
      }, 500)
    }

    const onCurrentPageChange = (page) => {
      selectedPage.value = page
      const payload = { filter: { limit: itemsPerPage.value, offset: itemsPerPage.value * (page - 1) } } as any
      if (tableSearch.value !== '') {
        payload.filter.title = tableSearch.value
      }
      store.dispatch(MutationsActions.API_GET_MUTATE_LEARNING_PATHS, payload).then(() => {
        MenuComponent.reinitialization()
      })
    }

    const onItemsPerPageChange = (rowsPerPage, page) => {
      itemsPerPage.value = rowsPerPage
      const payload = { filter: { limit: rowsPerPage, offset: rowsPerPage * (--page - 1) } } as any
      if (tableSearch.value !== '') {
        payload.filter.title = tableSearch.value
      }
      store.dispatch(MutationsActions.API_GET_MUTATE_LEARNING_PATHS, payload).then(() => {
        MenuComponent.reinitialization()
      })
    }
    const onDownloadAnalytics = (learningPathUuid) => {
      console.log(learningPathUuid)
      const filter: MetricsPayload = {
        metrics: ['learning_path_statistics'],
        filter: {
          learningPathUuid: learningPathUuid,
          format: 'xlsx'
        }
      }
      store.dispatch(Actions.API_GET_METRICS_BY_FILTER, filter)
    }
    const emitter = inject('emitter') as any // Inject `emitter`
    emitter.on('create-learning-path', (value) => {
      const newLearningPath = {
        title: 'Untitled new learning path',
        description: 'No description',
        objective: 'No objective',
        settings: {
          allowNotifyManagers: 1,
          allowMultipleAnswers: 0
        }
      } as NewLearningPath

      store.dispatch(Actions.API_CREATE_LEARNING_PATH, newLearningPath).then((response) => {
        router.push('/deploy/' + response.payload.uuid)
      }).catch((response) => {
        Swal.fire({
          text: response.data.error.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Try again',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      })
    })

    const onDeleteAction = (learningPathUuid) => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary'
        },
        buttonsStyling: false
      })

      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        html: '<div style="display: flex; flex-direction: column; "><i class="las la-exclamation-circle fs-1 mb-2"></i>All sessions associated will be deleted and participants will receive cancellation emails.</div>',
        animation: false,
        showCancelButton: true,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch(Actions.API_DELETE_LEARNING_PATH, learningPathUuid).then((response) => {
            const payload: ActivitySessionFilter = { filter: { limit: itemsPerPage.value } }
            store.dispatch(MutationsActions.API_GET_MUTATE_LEARNING_PATHS, payload).then(() => {
              store.dispatch(MutationsActions.API_GET_MUTATE_LEARNING_PATHS_COUNT)
              ElNotification.success({
                message: 'Learning path deleted.',
                dangerouslyUseHTMLString: true,
                customClass: 'houston-notification'
              })
              MenuComponent.reinitialization()
            })
          }).catch((error) => {
            console.log(error)
          })
        }
      })
    }

    onUnmounted(() => {
      // needed to not call multiple times the same handler
      emitter.off('create-learning-path')
    })

    onMounted(() => {
      const payload: ActivitySessionFilter = { filter: { limit: itemsPerPage.value } }
      store.dispatch(MutationsActions.API_GET_MUTATE_LEARNING_PATHS, payload).then(() => {
        store.dispatch(MutationsActions.API_GET_MUTATE_LEARNING_PATHS_COUNT)
        MenuComponent.reinitialization()
      })
      watch(() => tableData.value, (updated, oldAccount) => {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading')
      })

      if (tableData.value) {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading')
      }

      setCurrentPageTitle('Learning paths')
      setCurrentPageTitleButton({ name: 'New Learning Path', url: '', class: 'houston-btn-dark houston-btn-bounce', emits: { actionName: 'create-learning-path' } })
    })

    return {
      tableHeader,
      tableData,
      tableSearch,
      tableLoading,
      tablePagesCount,
      onItemsSearch,
      onItemsPerPageChange,
      onCurrentPageChange,
      onDeleteAction,
      getMedia,
      getStatusBadge,
      formatListDate,
      formatListHourInterval,
      extractParticipantCount,
      onDownloadAnalytics
    }
  }
})
